.root-div {
    position: absolute;
    width: 100%;
    left: 0;
}

.container-fluid {
    padding: 0 !important;
}

table {
    outline-style: solid;
    outline-width: 1px;
}

table td {
    vertical-align: middle !important;
    padding: 0 4px !important;
}

table th {
    vertical-align: middle !important;
    padding: 0 4px !important;
}

h4 {
    margin: 0 !important;
    padding-bottom: 2px !important;
}

input, select, textarea {
    display: inline !important;
    width: 100%;
    border: none !important;
    outline: none !important;
    scroll-behavior: smooth;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
    vertical-align: middle !important;
}

.bold {
    font-weight: bold;
}

.bg-custom {
    background-color: darkgreen;
}

.no-page-break {
    page-break-inside: avoid;
    padding-bottom: 4px;
}

.preview {
    font-size: 10pt !important;
}

#wrapper {
    padding-right: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 250px;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    background: #000;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#page-content-wrapper {
    width: 100%;
    position: absolute;
    padding: 8px !important;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-left: 0;
}


.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    text-indent: 20px;
    line-height: 40px;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #999999;
}

.sidebar-nav li a:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active, .sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
    color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
}


@media (min-width: 768px) {
    #wrapper {
        padding-right: 0;
    }

    #wrapper.toggled {
        padding-left: 250px;
    }

    #sidebar-wrapper {
        width: 0;
    }

    #wrapper.toggled #sidebar-wrapper {
        width: 250px;
    }

    #page-content-wrapper {
        position: relative;
    }

    #wrapper.toggled #page-content-wrapper {
        position: relative;
        margin-left: 0;
    }
}

.anchor {
    display: block;
    height: 92px;
    margin-top: -92px;
    visibility: hidden;
}

.btn:focus {
    outline: none !important;
    box-shadow: 0 0 0 0 !important;
}

#jagdamba-logo {
    height: 32px;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    width: 32px;
}

.override-sidebar-nav-li-a {
    color: rgba(255, 255, 0, 0.79) !important;
    font-weight: bold;
}

textarea {
    overflow: hidden !important;
    resize: none !important;
}

.btn-group-xs > .btn, .btn-xs {
    padding: .25rem .4rem;
    font-size: .875rem;
    line-height: .5;
    border-radius: .2rem;
}

.btn-no-bg-no-outline {
    border-color: transparent !important;
}

.center-vertical {
    vertical-align: middle !important;
    display: inline-block !important;
}

.error {
    text-align: center;
    margin-top: 64px;
}

label {
    padding: 0 !important;
    margin: 0 !important;
}

@media print {
    #page-content-wrapper {
        padding: 2px !important;
    }

    html, body {
        font-size: 10pt;
    }

    @page {
        size: A4;
        margin: 70mm 20mm 30mm 20mm;
    }
}
